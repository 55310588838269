<template>
  <!-- 日历日程页 -->
  <el-card>
    <div class="calendar">
      <!-- 第二部分 -->
      <div class="div">
        <!-- 日历 -->
        <div :class="showDivOpen || showStatistics ? 'calendar80' : 'calendar100'">
          <div v-show="showDateSelect" class="datePicker">
            <el-date-picker v-model="value" @change="changeValue" type="month" placeholder="选择年月"></el-date-picker>
          </div>
          <div class="right">
            <span>
              <el-select class="inputProject" v-model="center_id" multiple placeholder="请选择中心">
                <el-option v-for="itemPro in centerList" :key="'selectPro_' + itemPro.id" :label="itemPro.name" :value="itemPro.id"></el-option>
              </el-select>
            </span>
            <span>
              <el-select class="inputProject" v-model="projectid" multiple placeholder="请选择项目名称">
                <el-option v-for="itemPro in projectList" :key="'selectPro_' + itemPro.id" :label="itemPro.name" :value="itemPro.id"></el-option>
              </el-select>
            </span>
            <span>
              <el-select class="inputTeacher" v-model="togetherid" multiple placeholder="请选择教师名称">
                <el-option v-for="(item, index) in togetherList" :key="index" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </span>
            <el-button class="searchButton" @click="searchContent()">筛选</el-button>
            <!-- <el-button class="edit"  @click="showDialog('update')">修改</el-button> -->
          </div>
          <el-calendar v-model="value">
            <!-- slot 插槽 -->
            <template slot="dateCell" slot-scope="{ date, data }">
              <div>
                <!--这里原本有动态绑定的class，去掉-->
                <div class="date-el">
                  <el-checkbox v-show="showcheckBox" v-model="data.checkOneDay" @change="checkOneDay(data)"></el-checkbox>
                  {{ data.day.split('-').slice(2).join('') }}
                </div>
                <table>
                  <tr class="haveClass" v-for="(item, index) in dealMyDate(data, date)" :key="'open_' + index">
                    <td>
                      <el-checkbox
                        v-show="showcheckBox"
                        :disabled="item.disable"
                        v-model="item.checkModel"
                        @change="checked => checkboxChange(checked, item.open_id)"
                      ></el-checkbox>
                    </td>
                    <td >
                      <div>
                        {{ item.sksj }} -
                        {{ item.class_uname }}
                      </div>
                      <div>{{ item.name }} {{ item.rs }}</div>
                      <div>{{ item.locainfo }} </div>
                    </td>
                  </tr>
                </table>
              </div>
            </template>
          </el-calendar>
        </div>
      </div>
    </div>
  </el-card>
</template>
<style lang="scss">
@import '@/assets/css/teacher/calendar/CalendarNoScope.scss';
</style>
<style lang="scss" scoped>
@import '@/assets/css/teacher/center/openList.scss';
</style>

<script>
import Calendar from '@/assets/js/schoolAdmin/calendar/calendar.js';
export default {
  ...Calendar,
};
</script>
